<div class="row margin_60 padding_10">
    <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12"></div>
    <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
        <div class="row">
            <div class="col-xl-4 col-lg-3 col-md-12 col-sm-12">
                <strong>{{ 'fechaAprobacion' | translate }}</strong> {{ utils.dataAp }}
            </div>
            
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <strong>{{ 'tipusCensElectoral' | translate }}</strong>
            </div>
            
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <strong>{{ 'anioCensal' | translate }}</strong> {{ utils.anio }}
            </div>
            <div class="col-xl-2 col-lg-3 col-md-12 col-sm-12">
                {{ utils.dataAct }}
            </div>
        </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12"></div>
</div>