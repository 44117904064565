import { Component } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-genera-cens-public',
  templateUrl: './genera-cens-public.component.html',
  styleUrls: ['./genera-cens-public.component.css']
})
export class GeneraCensPublicComponent {

  constructor( public utils: UtilsService ) {
    
  }

}
