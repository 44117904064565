import { Component, OnInit } from '@angular/core';
import { CensService } from 'src/app/services/cens.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from 'src/app/services/utils.service';
import { CensPublic } from 'src/app/interfaces/cens-public';
import { CensElectoral } from 'src/app/interfaces/cens-electoral';

@Component({
  selector: 'app-cens-global',
  templateUrl: './cens-global.component.html',
  styleUrls: ['./cens-global.component.css']
})
export class CensGlobalComponent implements OnInit{

  constructor( private censService: CensService, public utils: UtilsService ) {

  }

  ngOnInit(): void {
    this.utils.esElectoral = false;
    this.utils.esGlobal = true;
    this.utils.esPublic = false;
  }

  emptyMessage: string = '';

  consultado: boolean = false;
  error: boolean = false;
  errorPublic: boolean = false;
  errorNif: boolean = false;
  errorCaptcha: boolean = false;
  errorCampos: boolean = false;

  hayDatos: boolean = false;
  hayDatosPublic: boolean = false;

  loadData() {

    this.consultado = false;
    this.hayDatos = false;
    this.hayDatosPublic = false;
    this.error = false;
    this.errorNif = false;
    this.errorCaptcha = false;
    this.errorCampos = false;

    this.utils.censPublic = [];
    this.utils.censElectoral = [];
    this.utils.genCens = [];
    this.utils.esPublic = false;
    this.utils.esElectoral = false;

    if ( this.utils.nif == '' && this.utils.tokenCaptcha != '' ) {
      console.log( 'NIF comprobacion vacio: ' + this.utils.nif );
      this.errorNif = true;

    } else if( this.utils.tokenCaptcha == '' && this.utils.nif != '' ) { 
      console.log( 'TOKEN CAPTCHA comprobacion vacio: ' + this.utils.nif );
      this.errorCaptcha = true;

    } else if( this.utils.tokenCaptcha == '' && this.utils.nif == '' ) {
      console.log( 'NIF y captcha comprobacion vacio: ' + this.utils.nif );
      this.errorCampos = true;

    } else {
     
      this.censService.getGeneracioCens().subscribe( data => {

        this.utils.genCens.push(<any>data);
        this.utils.dataAp = this.utils.genCens[0].dataAprovacio;
        this.utils.anio = this.utils.genCens[0].anyCensal;
        this.utils.dataAct = this.utils.genCens[0].dataActual;

      } );

      console.log( 'NIF: ' + this.utils.nif );

      this.censService.getCensPublic( (this.utils.nif).trim() ).subscribe({

        next: (data => {

          console.log( 'Consulta censPublic realizada: ' + data );
          this.consultado = true;
          this.utils.censPublic.push(...<CensPublic[]>data);

          if ( this.utils.censPublic.length > 0 ) {

            console.log( 'Consulta censPublic length: ' + this.utils.censPublic.length );
    
            this.hayDatosPublic = true;
            this.utils.censPublic.sort((a,b) => a.municipi.localeCompare(b.municipi));
    
          } else {
    
            console.log( 'Consulta censPublic empty' );
            this.emptyMessage = this.utils.getMessageEmpty();
    
          }

        }),

        error: (err => {

          console.log( 'Consulta censPublic error: ' + err );
          this.errorPublic = true;
          this.emptyMessage = 'ERROR: ('+err.status+')';

        }),

        complete: () => console.log('completado')

      });

      this.censService.getCensElectoral((this.utils.nif).trim() ).subscribe({

        next: (data => {

          this.consultado = true;
          this.utils.censElectoral.push(...<CensElectoral[]>data);
    
          if ( this.utils.censElectoral.length > 0 ) {
    
            this.hayDatos = true;
            this.utils.censElectoral.sort((a,b) => a.municipi.localeCompare(b.municipi));
    
          } else {

            this.emptyMessage = this.utils.getMessageEmpty();
    
          }

        }),

        error: (err => {

          this.error = true;
          this.emptyMessage = 'ERROR: ('+err.status+')';

        }),

        complete: () => { 
          console.log('completado');
        }

      });
    
    }

    grecaptcha.reset();
    this.utils.tokenCaptcha = '';

  }


}
