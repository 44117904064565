import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CensService {

  constructor( private http: HttpClient ) { }

  getGeneracioCens() {
    console.log(`censws/rest/generaciocens/-1` + "  generacio cens");
    return this.http.get(`censws/rest/generaciocens/-1`);

  }

  getCensPublic( nif: string ) {
    console.log(`censws/rest/censPublic/get/${nif}`+ "  PUBLIC");
    return this.http.get(`censws/rest/censPublic/get/${nif}`);

  }

  getCensElectoral( nif: string ) {
    console.log(`censws/rest/cens/get/${nif}` + "  ELECTORAL")
    return this.http.get(`censws/rest/cens/get/${nif}`);

  }

}
