import { Component } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-selector-language',
  templateUrl: './selector-language.component.html',
  styleUrls: ['./selector-language.component.css']
})
export class SelectorLanguageComponent {

  constructor( public utils: UtilsService ) {

  }

}
