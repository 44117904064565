<div class="row margin_60">
    <div class="col-lg-1 col-xl-1 col-sm-12 col-md-12"></div>
    <div class="col-lg-3 col-xl-3 col-sm-12 col-md-12">
        <mat-form-field class="example-full-width">
            <mat-label>NIF</mat-label>
            <input (input)="utilService.getNifValu($event)" matInput placeholder="A0000000X">
        </mat-form-field>
    </div>
    <div class="col-lg-8 col-xl-8 col-sm-12 col-md-12"></div>
</div>
