<div class="screen_div">
    <div class="container">
        <!-- inicio input y captcha -->
        <div class="row">
            <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12"></div>
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col_form">

                <app-selector-language></app-selector-language>
                <app-titulo-tipo-cens></app-titulo-tipo-cens>
                <app-input-nif></app-input-nif>
                <app-captcha></app-captcha>

                <div class="row margin_30">
                    <div class="col-1"></div>
                    <div class="col-3 center">
                        <button (click)="loadData()" class="btn btn-danger">{{ 'consultar' | translate }}</button>
                    </div>
                    <div class="col-8"></div>
                </div>

            </div>
            <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12"></div>
        </div>
        <!-- fin input y captcha -->
        <div *ngIf="hayDatos && consultado">
            <app-genera-cens></app-genera-cens>
            <app-table-electoral></app-table-electoral>
        </div>

        <div *ngIf="!hayDatos && consultado">
            <div class="row">
                <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12"></div>
                <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 margin_50 center">
                    <div class="alert alert-danger" role="alert">
                        {{ 'sinDatos' | translate }}
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12"></div>
            </div>
        </div>

        <div *ngIf="errorNif">
            <div class="row">
                <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12"></div>
                <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 margin_50 center">
                    <div class="alert alert-danger" role="alert">
                        {{ 'nifObligatorio' | translate }}
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12"></div>
            </div>
        </div>

        <div *ngIf="errorCaptcha">
            <div class="row">
                <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12"></div>
                <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 margin_50 center">
                    <div class="alert alert-danger" role="alert">
                        {{ 'captcha' | translate }}
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12"></div>
            </div>
        </div>

        <div *ngIf="errorCampos">
            <div class="row">
                <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12"></div>
                <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 margin_50 center">
                    <div class="alert alert-danger" role="alert">
                        {{ 'camposObligatorios' | translate }}
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12"></div>
            </div>
        </div>

        <div *ngIf="error">
            <div class="row">
                <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12"></div>
                <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 margin_50 center">
                    <div class="alert alert-danger" role="alert">
                        {{ emptyMessage }} - {{ 'serverError' | translate }}
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12"></div>
            </div>
        </div>

    </div>
</div>