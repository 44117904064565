import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort'
import { MatPaginatorIntl } from '@angular/material/paginator';

@Component({
  selector: 'app-table-public',
  templateUrl: './table-public.component.html',
  styleUrls: ['./table-public.component.css']
})
export class TablePublicComponent implements AfterViewInit{

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  @ViewChild(MatSort)
  sort!: MatSort;

  public _MatPaginatorIntl!: MatPaginatorIntl;

  constructor( public utils: UtilsService ) {

    console.log( 'Constructor TablePublicComponent' );

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public dataSource = new MatTableDataSource(this.utils.censPublic);

  applyFilter(event: Event) {
    let filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  displayedColumns = ['ordre', 'raoSocial', 'adreca', 'municipi', 'codiPostal', 'epigrafe'];
  color: string = "#EB0827";
  colorLetra: string = "white";
  size: string = "12px";
  border: string = "solid 1px grey"

  
}
