import { Component } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-titulo-tipo-cens',
  templateUrl: './titulo-tipo-cens.component.html',
  styleUrls: ['./titulo-tipo-cens.component.css']
})
export class TituloTipoCensComponent {

  constructor( public utils: UtilsService ) {

  }

}
