import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CensPublic } from '../interfaces/cens-public';
import { CensElectoral } from '../interfaces/cens-electoral';
import { CensGeneracio } from '../interfaces/cens-generacio';
import { CensService } from './cens.service';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor( public translate: TranslateService ) { }


  censPublic: CensPublic[] = [];
  censElectoral: CensElectoral[] = [];
  genCens: CensGeneracio[] = [];

  retorno: string = '0';
  nif: string = '';
  dataAp: string = '';
  anio: number = 0;
  dataAct: number = 0;
  esPublic: boolean = false;
  esElectoral: boolean = false;
  esGlobal: boolean = false;
  tokenCaptcha: string = '';
  language: string = 'ca';
  errorMessageSearch: string = '';

  public resolved(captchaResponse: string): void {
    //console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.tokenCaptcha = captchaResponse;
  }

  public getNifValu( event: any ) {

    this.nif = event.target.value;
    return this.nif;

  }

  public switchLang(lang: string) {

    this.translate.use(lang);
    this.language = lang;
 
  }

  getMessageEmpty(): string {

    if ( this.language == 'es' ) {
      this.errorMessageSearch = 'No existen registros para el NIF introducido';
    } else if ( this.language == 'en' ) {
      this.errorMessageSearch = 'There are no records for the NIF entered';
    } else {
      this.errorMessageSearch = 'No hi ha registres per al NIF introduït';
    }

    return this.errorMessageSearch;

  }

}
