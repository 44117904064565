import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort'

@Component({
  selector: 'app-table-electoral',
  templateUrl: './table-electoral.component.html',
  styleUrls: ['./table-electoral.component.css']
})
export class TableElectoralComponent implements AfterViewInit{

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  @ViewChild(MatSort)
  sort!: MatSort;

  constructor( public utils: UtilsService ) {

  }

  public dataSource = new MatTableDataSource(this.utils.censElectoral);

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    let filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  displayedColumns = ['grup', 'ordre', 'raoSocial', 'adreca', 'municipi', 'codiPostal', 'epigrafe'];
  color: string = "#EB0827";
  colorLetra: string = "white";
  size: string = "12px";
  border: string = "solid 1px grey"

}
