<script>
    console.log( 'TablePublicComponent - HTML start' );
</script>

<div class="row margin_30" style="padding: 10px;">
    <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12"></div>
    <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col_form">
        <mat-form-field>
            <mat-label>Filtro</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Barcelona" #input>
        </mat-form-field>
        <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="ordre">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'odre' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{ element.ordre }}</td>
                </ng-container>
                <ng-container matColumnDef="raoSocial">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'rSocial' | translate }}</th>
                    <td mat-cell *matCellDef="let element"> {{element.raoSocial}} </td>
                </ng-container>
                <ng-container matColumnDef="adreca">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'adreca' | translate }}</th>
                    <td mat-cell *matCellDef="let element"> {{element.adreca}} </td>
                </ng-container>
                <ng-container matColumnDef="municipi">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'municipi' | translate }}</th>
                    <td mat-cell *matCellDef="let element"> {{element.municipi}} </td>
                </ng-container>
                <ng-container matColumnDef="codiPostal">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>C.P.</th>
                    <td mat-cell *matCellDef="let element"> {{element.codiPostal}} </td>
                </ng-container>
                <ng-container matColumnDef="epigrafe">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Sec - Ep.</th>
                    <td mat-cell *matCellDef="let element"> {{element.seccio}} - {{ element.epigrafe }}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns" 
                    [style.background-color] = "color" 
                    [style.color] = "colorLetra" 
                    [style.font-size] = "size"
                    [style.border] = "border"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" 
                    [style.font-size] = "size"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="6">No se han encontrado coincidencias con "{{input.value}}"</td>
                </tr>
            </table>
            <mat-paginator [pageSizeOptions]="[10, 25, 50, dataSource.data.length]" aria-label="Select page of users"></mat-paginator>
        </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12"></div>
</div>

<script>
    console.log( 'TablePublicComponent - HTML end' );
</script>