import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CensPublicComponent } from './components/cens-public/cens-public.component';
import { CensElectoralComponent } from './components/cens-electoral/cens-electoral.component';
import { CensGlobalComponent } from './components/cens-global/cens-global.component';
import { RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';

const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forRoot(routes), RecaptchaModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
