<div class="row margin_50 margin_left_50">
    <div class="col-12">
        <img height="65" src="../assets/img/logo.png">
        <span style="float: right; cursor: pointer;">
            <a (click)="utils.switchLang('ca')"><strong>CA</strong></a>&nbsp;|
            <a (click)="utils.switchLang('es')"><strong>ES</strong></a>&nbsp;|
            <!---<a (click)="utils.switchLang('en')"><strong>EN</strong></a>--->
        </span>
    </div>
</div>
