import { Component, OnInit } from '@angular/core';

import { CensService } from 'src/app/services/cens.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from 'src/app/services/utils.service';
import { CensElectoral } from 'src/app/interfaces/cens-electoral';

@Component({
  selector: 'app-cens-electoral',
  templateUrl: './cens-electoral.component.html',
  styleUrls: ['./cens-electoral.component.css'],
})
export class CensElectoralComponent implements OnInit{

  constructor( private censService: CensService, public utils: UtilsService ) {
    
  }
  ngOnInit(): void {
    this.utils.esElectoral = true;
    this.utils.esGlobal = false;
    this.utils.esPublic = false;
  }

  emptyMessage: string = '';

  consultado: boolean = false;
  error: boolean = false;
  errorNif: boolean = false;
  errorCaptcha: boolean = false;
  errorCampos: boolean = false;

  nifInput: string = '';
  hayDatos: boolean = false;

  loadData() {

    this.consultado = false;
    this.hayDatos = false;
    this.error = false;
    this.errorNif = false;
    this.errorCaptcha = false;
    this.errorCampos = false;

    this.utils.censElectoral = [];
    this.utils.genCens = [];
    this.utils.esElectoral = false;
    this.utils.esPublic = false;

    if ( this.utils.nif == '' && this.utils.tokenCaptcha != '' ) {

      this.errorNif = true;

    } else if( this.utils.tokenCaptcha == '' && this.utils.nif != '' ) { 
    
      this.errorCaptcha = true;

    } else if( this.utils.tokenCaptcha == '' && this.utils.nif == '' ) {
    
      this.errorCampos = true;

    } else {
     
      this.censService.getGeneracioCens().subscribe( data => {

        this.utils.genCens.push(<any>data);
        this.utils.dataAp = this.utils.genCens[0].dataAprovacio;
        this.utils.anio = this.utils.genCens[0].anyCensal;
        this.utils.dataAct = this.utils.genCens[0].dataActual;

      } );

      this.censService.getCensElectoral( (this.utils.nif).trim() ).subscribe({
        next: (data => {

          this.consultado = true;
          this.utils.esElectoral = true;
          this.utils.censElectoral.push(...<CensElectoral[]>data);
    
          if ( this.utils.censElectoral.length > 0 ) {
    
            this.hayDatos = true;
            this.utils.censElectoral.sort((a,b) => a.municipi.localeCompare(b.municipi));
    
          } else {
    
            this.emptyMessage = this.utils.getMessageEmpty();
    
          }

        }),
        error: (err => { 

          this.error = true;
          this.emptyMessage = 'ERROR: ('+err.status+')';

        }),
        complete: () => console.info('complete') 
      });

    }

    grecaptcha.reset();
    this.utils.tokenCaptcha = '';

  }

}
