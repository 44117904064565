import { Component } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-input-nif',
  templateUrl: './input-nif.component.html',
  styleUrls: ['./input-nif.component.css']
})
export class InputNifComponent {

  constructor( public utilService: UtilsService ) {

  }

}
