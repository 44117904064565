import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FormsModule } from '@angular/forms';
import { RouterModule, UrlSegment, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// captcha
import { RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings, RecaptchaFormsModule } from 'ng-recaptcha';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// componentes aplicación
import { CensPublicComponent } from './components/cens-public/cens-public.component';
import { CensElectoralComponent } from './components/cens-electoral/cens-electoral.component';
import { CensGlobalComponent } from './components/cens-global/cens-global.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputNifComponent } from './components/input-nif/input-nif.component';
import { TablePublicComponent } from './components/table-public/table-public.component';
import { TituloTipoCensComponent } from './components/titulo-tipo-cens/titulo-tipo-cens.component';
import { GeneraCensPublicComponent } from './components/genera-cens-public/genera-cens-public.component'
import { TableElectoralComponent } from './components/table-electoral/table-electoral.component';
import { GeneraCensComponent } from './components/genera-cens/genera-cens.component';
import { SelectorLanguageComponent } from './components/selector-language/selector-language.component';
import { CaptchaComponent } from './components/captcha/captcha.component';


// angular material
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSortModule } from '@angular/material/sort';


//const globalSettings: RecaptchaSettings = { siteKey: '6LcOuyYTAAAAAHTjFuqhA52fmfJ_j5iFk5PsfXaU' }; //publica captcha cuenta oscar
const globalSettings: RecaptchaSettings = { siteKey: '6LcFGHEpAAAAAEBtgIOGUaBkc_bb8N_ol4JDEdrH' }; //publica captcha cuenta cambra
const appRoutes: Routes = [
  { path: 'cens-public', component: CensPublicComponent, title: 'Cens públic' },
  { path: 'cens-electoral', component: CensElectoralComponent, title: 'Cens Electoral' },
  { path: 'cens-global', component: CensGlobalComponent, title: 'Cens Global' },
];

@NgModule({
  declarations: [
    AppComponent,
    CensPublicComponent,
    CensElectoralComponent,
    CensGlobalComponent,
    InputNifComponent,
    TablePublicComponent,
    TableElectoralComponent,
    GeneraCensComponent,
    SelectorLanguageComponent,
    CaptchaComponent,
    TituloTipoCensComponent,
    GeneraCensPublicComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    RecaptchaFormsModule,
    MatTableModule,
    MatInputModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatSortModule,
    RouterModule.forRoot(
      appRoutes
    ),
    NgbModule,
    RecaptchaModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule
  ],
  exports: [
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule
  ],
  providers: [
    TablePublicComponent,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: globalSettings,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {

  return new TranslateHttpLoader(http);
 
}