import { Component } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-genera-cens',
  templateUrl: './genera-cens.component.html',
  styleUrls: ['./genera-cens.component.css']
})
export class GeneraCensComponent {

  constructor( public utils: UtilsService ) {

  }

}
