import { Component, OnInit } from '@angular/core';
import { CensService } from 'src/app/services/cens.service';

import { UtilsService } from 'src/app/services/utils.service';
import { CensPublic } from 'src/app/interfaces/cens-public';


export interface FormModel {
  captcha?: string;
}


@Component({
    selector: 'app-cens-public',
    templateUrl: './cens-public.component.html',
    styleUrls: ['./cens-public.component.css']
})

export class CensPublicComponent implements OnInit{

  public formModel: FormModel = {};

  constructor( private censService: CensService, public utils: UtilsService ) {

  }

  ngOnInit(): void {
    this.utils.esPublic = true;
    this.utils.esGlobal = false;
    this.utils.esElectoral = false;
  }

  emptyMessage: string = '';

  consultado: boolean = false;
  error: boolean = false;
  errorNif: boolean = false;
  errorCaptcha: boolean = false;
  errorCampos: boolean = false;

  nifInput: string = '';
  hayDatos: boolean = false;

  loadData() {

    this.consultado = false;
    this.hayDatos = false;
    this.error = false;
    this.errorNif = false;
    this.errorCaptcha = false;
    this.errorCampos = false;

    this.utils.censPublic = [];
    this.utils.genCens = [];
    this.utils.esPublic = false;
    this.utils.esElectoral = false;


    if ( this.utils.nif == '' && this.utils.tokenCaptcha != '' ) {

      this.errorNif = true;

    } else if( this.utils.tokenCaptcha == '' && this.utils.nif != '' ) { 
    
      this.errorCaptcha = true;

    } else if( this.utils.tokenCaptcha == '' && this.utils.nif == '' ) {
    
      this.errorCampos = true;

    } else {
     
      this.censService.getGeneracioCens().subscribe( data => {

        this.utils.genCens.push(<any>data);
        this.utils.dataAp = this.utils.genCens[0].dataAprovacio;
        this.utils.anio = this.utils.genCens[0].anyCensal;
        this.utils.dataAct = this.utils.genCens[0].dataActual;

      } );

      this.censService.getCensPublic(( this.utils.nif).trim() ).subscribe(
        {
          next: (data => {

            this.consultado = true;
            this.utils.esPublic = true;
            this.utils.censPublic.push(...<CensPublic[]>data);

            if ( this.utils.censPublic.length > 0 ) {

              this.hayDatos = true;
              this.utils.censPublic.sort((a,b) => a.ordre.localeCompare(b.ordre));
      
            } else {
      
              this.emptyMessage = this.utils.getMessageEmpty();
      
            }

          }),
          error: (err => { 

            this.error = true;
            this.emptyMessage = 'ERROR: ('+err.status+')';

          }),
          complete: () => console.info('complete') 
        }
      );

    }

    grecaptcha.reset();
    this.utils.tokenCaptcha = '';

  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

}
